import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "Headline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFPage = _resolveComponent("PDFPage")!
  const _component_PDFSampleBox = _resolveComponent("PDFSampleBox")!
  const _component_PDFSampleLineBlock = _resolveComponent("PDFSampleLineBlock")!
  const _component_PDFPageRender = _resolveComponent("PDFPageRender")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.fullSamples.length && !_ctx.minimalSamples.length)
      ? (_openBlock(), _createBlock(_component_PDFPage, {
          key: 0,
          class: "PDFSamples",
          contentClasses: 'PDFRichText',
          context: _ctx.context
        }, {
          sectionTitle: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.section) + ". " + _toDisplayString(_ctx.mixWB('ANALYSIS_RESULTS')), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('NO_SAMPLES_TAKEN')), 1)
          ]),
          _: 1
        }, 8, ["context"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fullSampleChunks, (chunk, index) => {
      return (_openBlock(), _createBlock(_component_PDFPage, {
        key: index,
        class: "PDFSamples",
        contentClasses: 'FullSamples',
        context: _ctx.context
      }, _createSlots({
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chunk, (sample) => {
            return (_openBlock(), _createBlock(_component_PDFSampleBox, {
              key: sample.hashID,
              context: _ctx.context,
              sample: sample,
              image: _ctx.getSampleImage(sample),
              units: _ctx.getSampleOriginUnits(sample),
              caption: _ctx.caption(sample),
              internalEquivalents: _ctx.getInternalEquivalentSamples(sample),
              floorplan: {
            image: _ctx.getSampleFloorPlan(sample),
            imageStyle: _ctx.getFloorplanImageStyle(sample),
            samplePosition: _ctx.getSamplePositionStyle(sample),
          },
              resultList: _ctx.getSampleResultList(sample)
            }, null, 8, ["context", "sample", "image", "units", "caption", "internalEquivalents", "floorplan", "resultList"]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.chunkSizes.full - chunk.length), (n) => {
            return (_openBlock(), _createElementBlock("div", {
              key: n,
              class: "PDFSampleBox IsEmpty"
            }))
          }), 128))
        ]),
        _: 2
      }, [
        (index === 0)
          ? {
              name: "sectionTitle",
              fn: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.section) + ". " + _toDisplayString(_ctx.mixWB('ANALYSIS_RESULTS')), 1)
              ])
            }
          : undefined
      ]), 1032, ["context"]))
    }), 128)),
    (_ctx.minimalSamples.length)
      ? (_openBlock(), _createBlock(_component_PDFPageRender, {
          key: 1,
          disableAutoRender: false
        }, {
          default: _withCtx(({ pageFilled, pageStartIndexes, pageElements, grapSlottedElements }) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pageStartIndexes, (startIndex, index) => {
              return (_openBlock(), _createBlock(_component_PDFPage, {
                key: startIndex,
                class: "PDFSamples",
                contentClasses: 'MinimalSamples',
                context: _ctx.context,
                elementsList: pageElements,
                startIndex: Number(startIndex) || 0,
                onElementsLoaded: grapSlottedElements,
                onAllElementsAdded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitCompletion())),
                onPageIsFull: pageFilled
              }, _createSlots({
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.minimalSamples, (sample) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: sample.id
                    }, [
                      (('teaser' in sample))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createElementVNode("h2", null, _toDisplayString(sample.title), 1),
                            _createElementVNode("p", null, _toDisplayString(sample.teaser), 1),
                            _createVNode(_component_PDFSampleLineBlock, {
                              context: _ctx.context,
                              sample: sample.firstSample,
                              image: _ctx.getSampleImage(sample.firstSample),
                              units: _ctx.getSampleOriginUnits(sample.firstSample),
                              caption: _ctx.caption(sample.firstSample)
                            }, null, 8, ["context", "sample", "image", "units", "caption"])
                          ]))
                        : (!('teaser' in sample))
                          ? (_openBlock(), _createBlock(_component_PDFSampleLineBlock, {
                              key: 1,
                              context: _ctx.context,
                              sample: sample,
                              image: _ctx.getSampleImage(sample),
                              units: _ctx.getSampleOriginUnits(sample),
                              caption: _ctx.caption(sample)
                            }, null, 8, ["context", "sample", "image", "units", "caption"]))
                          : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ]),
                _: 2
              }, [
                (index === 0 && _ctx.fullSamples.length === 0)
                  ? {
                      name: "sectionTitle",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.section) + ". " + _toDisplayString(_ctx.mixWB('ANALYSIS_RESULTS')), 1)
                      ])
                    }
                  : undefined
              ]), 1032, ["context", "elementsList", "startIndex", "onElementsLoaded", "onPageIsFull"]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}