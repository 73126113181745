
import PDFPage from '@/components/pdfs/PDFPage.vue'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import Unit from '@/globals/javascript/models/proUnit/Unit'
import { currentStore, proProjectsStore, resourcesStore } from '@/store'
import { chunk } from 'lodash-es'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import PDFPageRender from '@/components/pdfs/partials/PDFPageRender.vue'
import PDFSampleLineBlock from '@/components/pdfs/partials/PDFSampleLineBlock.vue'
import PDFSampleBox from '@/components/pdfs/partials/PDFSampleBox.vue'
import Building from '@/globals/javascript/models/proUnit/Building'

type IMinimalSample =
  | ProSample
  | {
      id: string
      title: string
      teaser: string
      firstSample: ProSample
    }

export default defineComponent({
  name: 'PDFSamples',
  emits: ['completed'],
  props: {
    section: {
      type: Number,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
  },
  data() {
    const buildingID = this.context.split('-')[1] ?? ''
    const isBuildingContext = !!buildingID

    return {
      isBuildingContext,
      buildingID,
      chunkSizes: {
        full: 4,
        minimal: 9,
      },
    }
  },
  computed: {
    ...mapState(proProjectsStore, ['allSamples']),
    ...mapState(resourcesStore, ['cleanAnalysisTestsAsArray']),
    ...mapState(currentStore, {
      projectSamples: 'samples',
      projectUnits: ({ units }) => units.all,
    }),
    fullSamples() {
      const { standard, assessed, equivalent } = this.projectSamples

      // Standard samples
      const standardAndRelated = standard.reduce((prev, sample) => {
        let relatedInternalEquivalents = equivalent.filter(
          (x) =>
            x.options.isInternalEquivalent && x.equivalentSampleID === sample.id
        )

        // If building context, only take
        if (this.isBuildingContext) {
          relatedInternalEquivalents = [
            ...relatedInternalEquivalents.filter((x) =>
              x
                .getSampledType()
                ?.unitIDs.some((id) => this.scopedUnitIDs.includes(id))
            ),
          ]

          let standarSampleMissing = true

          // If any related samples
          if (relatedInternalEquivalents.length) {
            standarSampleMissing = false
            prev.push(sample)
            prev.push(...relatedInternalEquivalents)
          }

          // If directly related standard sample not added
          if (
            standarSampleMissing &&
            this.scopedUnitIDs.includes(sample.floorPlanUnit)
          ) {
            prev.push(sample)
          }

          return prev
        }

        prev.push(sample)

        if (relatedInternalEquivalents.length) {
          prev.push(...relatedInternalEquivalents)
        }

        return prev
      }, [] as ProSample[])

      // Standard samples
      let assessedSamples = assessed

      if (this.isBuildingContext) {
        assessedSamples = assessed.filter((x) =>
          x
            .getSampledType()
            ?.unitIDs.some((id) => this.scopedUnitIDs.includes(id))
        )
      }

      // External equivalents
      let externalEquivalentSamples = equivalent.filter(
        (x) => x.otherScreeningID
      )

      if (this.isBuildingContext) {
        externalEquivalentSamples = externalEquivalentSamples.filter((x) =>
          x
            .getSampledType()
            ?.unitIDs.some((id) => this.scopedUnitIDs.includes(id))
        )
      }

      return [
        ...standardAndRelated,
        ...assessedSamples,
        ...externalEquivalentSamples,
      ]
    },
    minimalSamples() {
      const { postponed, existing } = this.projectSamples

      const data = {
        existing: [] as IMinimalSample[],
        postponed: [] as IMinimalSample[],
      }

      // Existing samples (related)
      let existingSamples = !this.isBuildingContext
        ? existing
        : existing.filter((x) =>
            x
              .getSampledType()
              ?.unitIDs.some((id) => this.scopedUnitIDs.includes(id))
          )

      // Postponed samples (related)
      let postponedSamples = !this.isBuildingContext
        ? postponed
        : postponed.filter((x) =>
            x
              .getSampledType()
              ?.unitIDs.some((id) => this.scopedUnitIDs.includes(id))
          )

      if (existingSamples.length) {
        const existingHeader = {
          id: 'existing',
          title: mixWB('EXISTING_SAMPLES'),
          teaser: mixWB('EXISTING_SAMPLES_SECTION_INFO_TEXT'),
          firstSample: existingSamples[0],
        }

        data.existing.push(existingHeader, ...existingSamples.slice(1))
      }

      if (postponedSamples.length) {
        const postponedHeader = {
          id: 'postponed',
          title: mixWB('POSTPONED_SAMPLES'),
          teaser: mixWB('POSTPONED_SAMPLE_RECOMMENDATION_TEXT'),
          firstSample: postponedSamples[0],
        }

        data.postponed.push(postponedHeader, ...postponedSamples.slice(1))
      }

      return [...data.existing, ...data.postponed]
    },
    fullSampleChunks(): ProSample[][] {
      return chunk(this.fullSamples, this.chunkSizes.full)
    },
    scopedUnitIDs() {
      if (this.isBuildingContext) {
        const contextUnit = this.projectUnits?.find(
          (x) => x.id === this.buildingID
        )

        if (!contextUnit || !(contextUnit instanceof Building)) {
          return []
        }

        return [
          contextUnit.id,
          ...contextUnit.floorIDs,
          ...contextUnit.apartmentIDs,
        ]
      }

      return this.projectUnits.map((x) => x.id)
    },
  },
  methods: {
    emitCompletion() {
      this.$emit('completed')
    },
    getInternalEquivalentSamples(sample: ProSample) {
      return this.projectSamples.equivalent.filter(
        (x) =>
          x.equivalentSampleID === sample.id && x.options.isInternalEquivalent
      )
    },
    getSampleImage(sample: ProSample) {
      // If sample is from other project - get images from original sample
      if (sample.otherScreeningID) {
        const _sample = this.allSamples.find(
          (s) => s.id === sample.equivalentSampleID
        )

        const { close } = _sample?.images ?? { close: null }

        return close
      }

      // If assesment use image of type
      if (
        ['equivalent', 'existing', 'assessed', 'postponed'].includes(
          sample.sampleType
        )
      ) {
        const typeImage = sample.getSampledType()?.images[0] ?? null

        return typeImage
      }

      // Use current samples images
      const { close } = sample.images

      return close
    },
    getSampleFloorPlan(sample: ProSample) {
      const { floorPlanUnit, otherScreeningID } = sample

      // Don't show floor plans from other projects
      if (otherScreeningID) {
        return null
      }

      // If no floor plan is assigned
      if (!floorPlanUnit) {
        return null
      }

      // Get unit where sample was taken
      const unit = Unit.getUnitById(floorPlanUnit)

      if (!unit) {
        return null
      }

      // Get first floorplan
      const [floorPlan] = unit.getFloorPlan()

      return floorPlan
    },
    getSamplePositionStyle(sample: ProSample) {
      const { x, y } = sample.floorPlanPosition

      if (x === null || y === null) {
        return {}
      }

      // Floorplan images are always saved in Portrait (via PRO)
      // - Simply assign x on x-axis and y on y-axis
      return {
        top: `${y}%`,
        left: `${x}%`,
      }
    },
    getFloorplanImageStyle(sample: ProSample) {
      const { x, y } = sample.floorPlanPosition

      if (x === null || y === null) {
        return {}
      }

      return { objectPosition: `${x}% ${y}%` }
    },
    getSampleResultList(sample: ProSample) {
      // If sample is from other project - get images from original sample
      const baseSample = !sample.otherScreeningID
        ? sample
        : this.allSamples.find((s) => s.id === sample.equivalentSampleID)

      if (!baseSample) {
        return []
      }

      const results = baseSample.getAnalysisTestListResults()

      return results.reduce(
        (prev, result) => {
          // Omit non-analysed tests (Using class since result.item isn't dependable)
          if (result.class === 'NotAnalysed') {
            return prev
          }

          const analysisTest = this.cleanAnalysisTestsAsArray.find(
            (x) => x.id === result.id
          )

          prev.push({
            label: this.mixWB(analysisTest?.translation ?? ''),
            value: result.text,
            class: result.class,
          })

          return prev
        },
        [] as {
          label: string
          value: string
          class: string
        }[]
      )
    },
    getSampleOriginUnits(sample: ProSample) {
      const sampledType = sample.getSampledType()
      if (!sampledType) {
        return '-'
      }

      const unitIDs = sampledType.unitIDs ?? []

      if (!unitIDs.length) {
        return '-'
      }

      return unitIDs
        .map(
          (id) =>
            this.projectUnits.find((u) => u.id === id)?.getFullTitle() ?? null
        )
        .filter(Boolean)
        .join(', ')
    },
    caption(sample: ProSample) {
      if (!sample) {
        return ''
      }

      const { sampleType, kindOfSample } = sample

      switch (sampleType) {
        case 'assessed':
          return kindOfSample === 'material'
            ? this.mixWB('ASSESSMENT_OF_MATERIAL')
            : this.mixWB('ASSESSMENT_OF_COATING')

        case 'existing':
          return kindOfSample === 'material'
            ? this.mixWB('RESULT_FOR_MATERIAL')
            : this.mixWB('RESULT_FOR_COATING')

        case 'standard':
        case 'equivalent':
        case 'postponed':
          return kindOfSample === 'material'
            ? this.mixWB('SAMPLE_OF_MATERIAL')
            : this.mixWB('SAMPLE_OF_COATING')
      }

      return ''
    },
  },
  components: {
    PDFPage,
    PDFPageRender,
    PDFSampleLineBlock,
    PDFSampleBox,
  },
  mounted() {
    if (!this.minimalSamples.length) {
      this.emitCompletion()
    }
  },
})
