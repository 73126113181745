import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment, renderList as _renderList, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "PDFSampleBox"
}
const _hoisted_2 = { class: "Top" }
const _hoisted_3 = { class: "Title" }
const _hoisted_4 = { class: "Bottom" }
const _hoisted_5 = { class: "Results" }
const _hoisted_6 = { class: "StatusLine" }
const _hoisted_7 = {
  key: 1,
  class: "PDFSampleBox"
}
const _hoisted_8 = { class: "Top" }
const _hoisted_9 = { class: "Title" }
const _hoisted_10 = ["id"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "Bottom" }
const _hoisted_14 = { class: "Results" }
const _hoisted_15 = { class: "StatusLine" }
const _hoisted_16 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_PDFFloorplanBlock = _resolveComponent("PDFFloorplanBlock")!

  return (_ctx.sample.equivalentNumber && !_ctx.sample.otherScreeningID)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["OverallStatus", _ctx.sample.getOverallResultClass()])
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.getInternalEquivalentSampleTypeID()), 1)
          ], 2),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.sample.getFullTitle(true)), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.caption), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.getInternalEquivalentReferenceText()), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('UNITS')) + ": " + _toDisplayString(_ctx.units), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.image)
            ? (_openBlock(), _createBlock(_component_Image, {
                key: 0,
                class: "SampleImage",
                context: _ctx.context,
                image: _ctx.image,
                loading: "eager",
                transformation: 'square_400',
                dimensions: {width: 130, height: 130}
              }, null, 8, ["context", "image"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.mixWB('RESULT')) + ": ", 1),
              _createElementVNode("span", {
                class: _normalizeClass(["Status", _ctx.sample.getStatus().className])
              }, _toDisplayString(_ctx.sample.getStatus().text), 3)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('VIEW_REPRESENTATIVE_SAMPLE_X_TEXT', [`P${_ctx.sample.equivalentSampleNumber}`])), 1)
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: _normalizeClass(["OverallStatus", _ctx.sample.getOverallResultClass()])
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.sample.sampleTypeID), 1)
          ], 2),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("span", {
              id: _ctx.sample.sampleTypeID
            }, _toDisplayString(_ctx.sample.getFullTitle(true)), 9, _hoisted_10),
            _createElementVNode("span", null, _toDisplayString(_ctx.caption), 1),
            (_ctx.getInternalEquivalentSamplesText())
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.getInternalEquivalentSamplesText()), 1))
              : _createCommentVNode("", true),
            (_ctx.sample.otherScreeningID)
              ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.mixWB('EQUIVALENT_SAMPLE_X_FROM_OTHER_PROJECT_Y', [`P${_ctx.sample.equivalentSampleNumber}`, _ctx.sample.getProject(_ctx.sample.otherScreeningID)?.getCaseNumber() ?? ''])), 1))
              : _createCommentVNode("", true),
            _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('UNITS')) + ": " + _toDisplayString(_ctx.units), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          (_ctx.image)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_Image, {
                  class: "SampleImage",
                  context: _ctx.context,
                  image: _ctx.image,
                  loading: "eager",
                  transformation: 'square_400',
                  dimensions: {width: 130, height: 130}
                }, null, 8, ["context", "image"]),
                _createVNode(_component_PDFFloorplanBlock, {
                  context: _ctx.context,
                  sample: _ctx.sample
                }, null, 8, ["context", "sample"])
              ], 64))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createTextVNode(_toDisplayString(_ctx.mixWB('RESULT')) + ": ", 1),
              _createElementVNode("span", {
                class: _normalizeClass(["Status", _ctx.sample.getStatus().className])
              }, _toDisplayString(_ctx.sample.getStatus().text), 3)
            ]),
            (_ctx.sample.options.isAalholmSlotDustSample)
              ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.mixWB('W: Resultatet for prøven foreligger som bilag, særskilt fra dette dokument. Se henvisningen i bemærkninger.')), 1))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "ResultsList",
                  style: _normalizeStyle(_ctx.resultList.length > 15  && { fontSize: '0.571rem'})
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resultList, (result) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: result.label
                    }, [
                      _createElementVNode("span", null, _toDisplayString(result.label), 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(["Status", result.class])
                      }, _toDisplayString(result.value), 3)
                    ], 64))
                  }), 128))
                ], 4))
          ])
        ])
      ]))
}