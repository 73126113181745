
import Image from '@/components/Image.vue'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { IImage } from '@/__types__/_pro/partials/image'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PDFSampleLineBlock',
  props: {
    context: {
      type: String,
      required: true,
    },
    sample: {
      type: Object as PropType<ProSample>,
      required: true,
    },
    image: {
      type: Object as PropType<IImage>,
      required: true,
    },
    units: {
      type: String,
      required: true,
    },
    caption: {
      type: String,
      required: true,
    },
  },
  components: { Image },
})
