
import Image from '@/components/Image.vue'
import PDFFloorplanBlock from '@/components/pdfs/partials/PDFFloorplanBlock.vue'
import versionKeys from '@/globals/javascript/constants/versionKeys'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { IImage } from '@/__types__/_pro/partials/image'
import { currentStore } from '@/store'
import { compare } from 'compare-versions'
import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PDFSampleBox',
  props: {
    context: {
      type: String,
      required: true,
    },
    sample: {
      type: Object as PropType<ProSample>,
      required: true,
    },
    image: {
      type: Object as PropType<IImage>,
      required: true,
    },
    units: {
      type: String,
      required: true,
    },
    caption: {
      type: String,
      required: true,
    },
    internalEquivalents: {
      type: Array as PropType<ProSample[]>,
      default: () => [],
    },
    floorplan: {
      type: Object as PropType<{
        image: IImage
        imageStyle: { objectPosition: string }
        samplePosition: { top: string; left: string }
      }>,
      default: null,
    },
    resultList: {
      type: Array as PropType<
        {
          label: string
          value: string
          class: string
        }[]
      >,
      default: () => [],
    },
  },
  computed: {
    ...mapState(currentStore, ['projectProVersion']),
  },
  methods: {
    getInternalEquivalentSampleTypeID() {
      // VERSION CHECK: Same or newer than 2.8.4
      if (
        compare(
          this.projectProVersion,
          versionKeys.HIDE_EQUIVALENT_NUMBERS,
          '>='
        )
      ) {
        return ''
      }
      // VERSION CHECK: Same or older than 2.8.4
      else {
        return this.sample.sampleTypeID
      }
    },
    getInternalEquivalentReferenceText() {
      // VERSION CHECK: Same or newer than 2.8.4
      if (
        compare(
          this.projectProVersion,
          versionKeys.HIDE_EQUIVALENT_NUMBERS,
          '>='
        )
      ) {
        return this.mixWB('REPRESENTED_BY_X', [
          `P${this.sample.equivalentSampleNumber}`,
        ])
      }
      // VERSION CHECK: Same or older than 2.8.4
      else {
        return this.mixWB('EQUIVALENT_TO_X', [
          `P${this.sample.equivalentSampleNumber}`,
        ])
      }
    },
    getInternalEquivalentSamplesText() {
      // VERSION CHECK: Same or newer than 2.8.4
      if (
        compare(
          this.projectProVersion,
          versionKeys.HIDE_EQUIVALENT_NUMBERS,
          '>='
        )
      ) {
        if (!this.internalEquivalents.length) {
          return ''
        }
        return this.mixWB(
          'THIS_SAMPLE_IS_REPRESENTATIVE_OF_SEVERAL_BUILDING_PARTS'
        )
      }
      // VERSION CHECK: Same or older than 2.8.4
      else {
        if (!this.internalEquivalents.length) {
          return ''
        }
        return `${this.mixWB('EQUIVALENT_SAMPLES')}: ${this.internalEquivalents
          .map((x) => x.sampleTypeID)
          .join(', ')}`
      }
    },
  },
  components: { Image, PDFFloorplanBlock },
})
