import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "PDFSampleLineBlock Sample" }
const _hoisted_2 = { class: "Info" }
const _hoisted_3 = { class: "Top" }
const _hoisted_4 = { class: "Title" }
const _hoisted_5 = { class: "Bottom" }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Image, {
      context: _ctx.context,
      image: _ctx.image,
      transformation: 'square_200',
      loading: "eager",
      dimensions: {width: 100, height: 100}
    }, null, 8, ["context", "image"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["OverallStatus", _ctx.sample.getOverallResultClass()])
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.sample.sampleTypeID), 1)
        ], 2),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_ctx.sample.getFullTitle(true)), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.caption), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('UNITS')) + ": " + _toDisplayString(_ctx.units), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.sample.existingResultNumber)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('RESULT')) + ": " + _toDisplayString(_ctx.sample.getResultLabel()), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('REFERENCE_2')) + ": " + _toDisplayString(_ctx.sample.getResultNotes()?.explanation), 1)
            ], 64))
          : (_ctx.sample.postponeNumber)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.mixWB('SAMPLING_WAS_NOT_POSSIBLE_DURING_THE_MAPPING')), 1))
            : _createCommentVNode("", true)
      ])
    ])
  ]))
}